[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 34px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
  color: #252528;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #1565c0;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 16px;
  height: 16px;
  background: #1565c0;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.mat-mdc-radio-button {
  .mat-radio-label-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #252528;
  }

  .mat-radio-container {
    width: 16px;
    height: 16px;

    .mat-radio-outer-circle {
      z-index: 2;
      width: 16px;
      height: 16px;
      border: 1px solid #a3a4a8;
    }

    .mat-radio-inner-circle {
      z-index: 2;
      width: 16px;
      height: 16px;
    }

    .mat-ripple {
      left: calc(50% - 12px);
      top: calc(50% - 12px);
      height: 24px;
      width: 24px;
    }

    &:hover {
      .mat-radio-outer-circle {
        border-color: #2196f3;
      }

      .mat-ripple {
        .mat-ripple-element {
          background-color: #e3f2fd;
          opacity: 1;
        }
      }
    }
  }

  &.cdk-mouse-focused {
    .mat-radio-container {
      .mat-radio-outer-circle {
        box-shadow: 0 0 0 2px #2196f33d;
      }
    }
  }

  &.mat-radio-checked {
    .mat-radio-container {
      .mat-radio-outer-circle {
      }

      .mat-radio-inner-circle {
        background-color: #1565c0;
      }
    }
  }

  &.mat-radio-disabled {
    .mat-radio-container {
      pointer-events: none;

      .mat-radio-outer-circle {
        border-color: #e4e5e9;
        background-color: #f1f1f5;
      }

      .mat-radio-inner-circle {
        background-color: #e4e5e9;
      }
    }
  }
}
