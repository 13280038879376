.mdc-checkbox__background {
  border: 1px solid $main-color-800 !important;
  border-radius: 4px !important;
  background-color: $main-color-800 !important;
  width: 16px !important;
  height: 16px !important;
  top: 9px !important;

  .mdc-checkbox__checkmark-path {
    display: none;
  }
  .mdc-checkbox__checkmark {
    background: url('~src/assets/icons/tick-white.svg') center center no-repeat
      $main-color-800;
    background-size: 12px;
  }
}

.mat-checkbox-frame {
  border: 1px solid $main-color-800;
  border-radius: 4px;
  width: 16px;
  height: 16px;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: url('~src/assets/icons/tick-white.svg') center center no-repeat
    $main-color-800;
  background-size: 11px;

  svg.mat-checkbox-checkmark {
    display: none;
  }
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background: url('~src/assets/icons/minus-white.svg') center center no-repeat
    $main-color-800;
  background-size: 11px;
}

.mat-checkbox-inner-container,
.mat-checkbox-frame {
  width: 16px !important;
  height: 16px !important;
}

.mdc-checkbox {
  padding: 8px !important;
  margin: 0 !important;
  height: 20px !important;
  .mdc-checkbox__native-control {
    height: 30px !important;
  }
}
.mat-mdc-checkbox-touch-target {
  width: 30px !important;
  height: 30px !important;
}

.mdc-label {
  padding-left: 0 !important;
  font-size: 16px !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none !important;
}
