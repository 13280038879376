@mixin for-tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin for-laptop {
  @media screen and (max-width: 1300px) {
    @content;
  }
}

@mixin for-desktop {
  @media screen and (max-width: 1440px) {
    @content;
  }
}


@mixin for-mobile {
  @media screen and (max-width: 576px) {
    @content;
  }
}
