:root {
  /* Color styles */
  --blue-900: rgba(13, 71, 161, 1);
  --blue-800: rgba(21, 101, 192, 1);
  --blue-700: rgba(25, 118, 210, 1);
  --blue-600: rgba(30, 136, 229, 1);
  --blue-500: rgba(33, 150, 243, 1);
  --blue-400: rgba(66, 165, 245, 1);
  --blue-300: rgba(100, 181, 246, 1);
  --blue-200: rgba(100, 181, 246, 1);
  --blue-100: rgba(187, 222, 251, 1);
  --blue-50: rgba(227, 242, 253, 1);

  --black-900: rgba(37, 37, 40, 1);
  --black-800: rgba(70, 71, 74, 1);
  --black-700: rgba(101, 102, 106, 1);
  --black-600: rgba(121, 122, 126, 1);
  --black-500: rgba(163, 164, 168, 1);
  --black-400: rgba(194, 194, 198, 1);
  --black-300: rgba(228, 229, 233, 1);
  --black-200: rgba(241, 241, 245, 1);
  --black-100: rgba(246, 247, 251, 1);
  --black-50: rgba(251, 250, 255, 1);


  --white-100: rgba(255, 255, 255, 1);
  --white-90: rgba(255, 255, 255, 0.8999999761581421);
  --white-80: rgba(255, 255, 255, 0.800000011920929);
  --white-70: rgba(255, 255, 255, 0.699999988079071);
  --white-60: rgba(255, 255, 255, 0.6000000238418579);
  --white-50: rgba(255, 255, 255, 0.5);
  --white-40: rgba(255, 255, 255, 0.4000000059604645);
  --white-30: rgba(255, 255, 255, 0.30000001192092896);
  --white-20: rgba(255, 255, 255, 0.20000000298023224);

  --yellow-900: rgba(234, 129, 13, 1);
  --yellow-800: rgba(238, 169, 34, 1);
  --yellow-700: rgba(235, 176, 44, 1);
  --yellow-600: rgba(238, 198, 50, 1);
  --yellow-500: rgba(241, 216, 55, 1);
  --yellow-400: rgba(242, 236, 89, 1);
  --yellow-300: rgba(245, 241, 120, 1);
  --yellow-200: rgba(248, 245, 158, 1);
  --yellow-100: rgba(251, 243, 193, 1);
  --yellow-50: rgba(253, 251, 230, 1);



  --success-900: rgba(27, 94, 31, 1);
  --success-800: rgba(46, 125, 49, 1);
  --success-700: rgba(56, 142, 59, 1);
  --success-600: rgba(67, 160, 70, 1);
  --success-500: rgba(76, 175, 80, 1);
  --success-400: rgba(102, 187, 105, 1);
  --success-300: rgba(129, 199, 132, 1);
  --success-200: rgba(165, 214, 167, 1);
  --success-100: rgba(200, 230, 201, 1);
  --success-50: rgba(232, 245, 233, 1);



  --attention-900: rgba(230, 77, 3, 1);
  --attention-800: rgba(239, 105, 2, 1);
  --attention-700: rgba(245, 121, 2, 1);
  --attention-600: rgba(251, 137, 1, 1);
  --attention-500: rgba(255, 148, 0, 1);
  --attention-400: rgba(255, 164, 37, 1);
  --attention-300: rgba(255, 181, 76, 1);
  --attention-200: rgba(255, 202, 127, 1);
  --attention-100: rgba(255, 223, 177, 1);
  --attention-50: rgba(255, 243, 224, 1);


  --error-900: rgba(178, 30, 27, 1);
  --error-800: rgba(193, 41, 38, 1);
  --error-700: rgba(206, 48, 45, 1);
  --error-600: rgba(224, 58, 51, 1);
  --error-500: rgba(239, 68, 52, 1);
  --error-400: rgba(235, 83, 78, 1);
  --error-300: rgba(225, 114, 113, 1);
  --error-200: rgba(236, 153, 153, 1);
  --error-100: rgba(254, 205, 209, 1);
  --error-50: rgba(254, 235, 238, 1);



  --purple-900: rgba(35, 16, 174, 1);
  --purple-800: rgba(91, 19, 185, 1);
  --purple-700: rgba(111, 21, 191, 1);
  --purple-600: rgba(134, 23, 199, 1);
  --purple-500: rgba(151, 23, 205, 1);
  --purple-400: rgba(166, 64, 214, 1);
  --purple-300: rgba(182, 100, 221, 1);
  --purple-200: rgba(204, 145, 231, 1);
  --purple-100: rgba(208, 193, 242, 1);
  --purple-50: rgba(243, 229, 249, 1);


  --teal-900: rgba(8, 99, 95, 1);
  --teal-800: rgba(0, 119, 121, 1);
  --teal-700: rgba(5, 154, 160, 1);
  --teal-600: rgba(0, 175, 185, 1);
  --teal-500: rgba(0, 192, 203, 1);
  --teal-400: rgba(42, 202, 208, 1);
  --teal-300: rgba(80, 211, 216, 1);
  --teal-200: rgba(130, 224, 226, 1);
  --teal-100: rgba(174, 231, 230, 1);
  --teal-50: rgba(222, 245, 245, 1);



  --pink-900: rgba(133, 15, 86, 1);
  --pink-800: rgba(171, 19, 96, 1);
  --pink-700: rgba(192, 21, 100, 1);
  --pink-600: rgba(215, 22, 106, 1);
  --pink-500: rgba(232, 23, 111, 1);
  --pink-400: rgba(234, 63, 132, 1);
  --pink-300: rgba(237, 98, 155, 1);
  --pink-200: rgba(241, 143, 184, 1);
  --pink-100: rgba(246, 187, 212, 1);
  --pink-50: rgba(251, 228, 238, 1);



  --indigo-900: rgba(49, 46, 129, 1);
  --indigo-800: rgba(55, 48, 163, 1);
  --indigo-700: rgba(67, 56, 202, 1);
  --indigo-600: rgba(79, 70, 229, 1);
  --indigo-500: rgba(99, 102, 241, 1);
  --indigo-400: rgba(129, 140, 248, 1);
  --indigo-300: rgba(165, 180, 252, 1);
  --indigo-200: rgba(199, 210, 254, 1);
  --indigo-100: rgba(224, 231, 255, 1);
  --indigo-50: rgba(238, 242, 255, 1);


  --violet-900: rgba(76, 29, 149, 1);
  --violet-800: rgba(91, 33, 182, 1);
  --violet-700: rgba(109, 40, 217, 1);
  --violet-600: rgba(124, 58, 237, 1);
  --violet-500: rgba(139, 92, 246, 1);
  --violet-400: rgba(167, 139, 250, 1);
  --violet-300: rgba(196, 181, 253, 1);
  --violet-200: rgba(221, 214, 254, 1);
  --violet-100: rgba(237, 233, 254, 1);
  --violet-50: rgba(245, 243, 255, 1);



  --fuchsia-900: rgba(112, 26, 117, 1);
  --fuchsia-800: rgba(134, 25, 143, 1);
  --fuchsia-700: rgba(162, 28, 175, 1);
  --fuchsia-600: rgba(192, 38, 211, 1);
  --fuchsia-500: rgba(217, 70, 239, 1);
  --fuchsia-400: rgba(232, 121, 249, 1);
  --fuchsia-300: rgba(240, 171, 252, 1);
  --fuchsia-200: rgba(245, 208, 254, 1);
  --fuchsia-100: rgba(250, 232, 255, 1);
  --fuchsia-50: rgba(253, 244, 255, 1);
}
