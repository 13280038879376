@import '../mixin';
@import '../components/color';
@import '../breakpoints';

.cdk-overlay-dark-backdrop {
  background: rgba(37, 37, 40, 0.25) !important;
}

.mat-mdc-dialog-container {
  box-shadow: none !important;

  .icon.icon-close {
    cursor: pointer;
  }
}

.dialog {
  &__content {
    margin-top: 2.5px;

    .icon {
      width: 43px;
      height: 43px;

      &-delete,
      &-stop,
      &-delete_channel,
      &-trash {
        background-color: $error-500;
      }

      &-reset {
        @extend .icon-bar-chart;
        background: $error-500;
      }

      &-copy, &-check-circle {
        background-color: $main-color-800;
      }

      &-run {
        background-color: $success-500;
      }

      @include for-tablet {
        width: 32px;
        height: 32px;
      }
    }
  }

  .btn-ok {
    margin: 24px 0 0;

    @include for-tablet {
      margin: 16px 0 0;
      padding: 10px 16px;
    }
  }

  &__title {
    margin: 24px 0 0 0;
    @include text(24px, 24px, 700);

    @include for-tablet {
      margin: 16px 0 0 0;
      @include text(20px, 20px, 700);
    }
  }

  &__text {
    margin: 24px 0;
    @include text(14px, 16px, 400, $black-700);

    @include for-tablet {
      margin: 16px 0;
      @include text(12px, 16px, 400, $black-700);
    }
  }

  .btn-tertiary {
    margin: 8px 0 0 0;
    @include text(16px, 24px, 400, $black-700);
  }

  .btn {
    &.copy {
      background: $main-color-800;
    }

    &.run {
      background: $success-500;
    }
  }

  &__more {
    display: none;

    @include for-tablet {
      display: block;
    }
  }

  @include for-tablet {
    .btn {
      font-size: 12px;
      line-height: 16px;
    }

    &__cancel {
      display: none;
    }
  }
}

.bot-statistics>.mat-mdc-dialog-container {
  padding: 0;
}

.change-tariff-dialog mat-dialog-container {
  padding: 0 !important;
}


.condition-dialog-container {
  height: 100%;
  background: #fff0;

  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 0 !important;
    background: transparent;

    app-segmentation-condition-dialog {
      display: flex;
      height: 100%;
    }
  }
}

.condition-dialog-backdrop {
  background: #25252866;
}


.dialog-v2 {
  .dialog {
    width: 100%;

    &__icon {
      text-align: center;
      margin-bottom: 24px;

      .icon {
        width: 48px;
        height: 48px;
      }
    }

    &__image-wrapper {
      display: flex;
      justify-content: center;
    }

    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      margin-top: 12px;
      margin-bottom: 16px;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    &__subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $black-700;
      text-align: center;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      gap: 16px;

      margin: 0;
      margin-top: 16px;

      .btn {
        padding: 12px 24px;
        height: 48px;
        margin: 0;

        @media only screen and (max-width: 768px) {
          height: 36px;
        }
      }

      .btn-tertiary {
        font-weight: 400;
      }
    }

    .channel-type-item {
      &__text {
        @media only screen and (max-width: 768px) {
          font-size: 12px;
          line-height: 12px;
        }
      }

      img {
        width: 16px;
        height: 16px;

        @media only screen and (max-width: 768px) {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
