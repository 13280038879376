@import 'color';

.icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-color: $black-900;
  -webkit-mask-size: contain !important;
  mask-size: contain !important;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  &-robot {
    -webkit-mask: url('../../assets/icons/robot.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/robot.svg') no-repeat 50% 50%;
  }

  &-cubik {
    background: url('../../assets/icons/cubik.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-darts {
    background: url('../../assets/icons/darts.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-bouling {
    background: url('../../assets/icons/bouling.svg') center transparent
      no-repeat !important;
    background-size: contain !important;
  }

  &-basketbol {
    background: url('../../assets/icons/basketbol.svg') center transparent
      no-repeat !important;
    background-size: contain !important;
  }

  &-football {
    background: url('../../assets/icons/football.svg') center transparent
      no-repeat !important;
    background-size: contain !important;
  }

  &-sloti {
    background: url('../../assets/icons/sloti.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-rotate-180 {
    transform: rotate(180deg);
  }

  &-rotate-90 {
    transform: rotate(90deg);
  }

  &-loader {
    -webkit-mask: url('../../assets/images/loader.svg') no-repeat 50% 50%;
    mask: url('../../assets/images/loader.svg') no-repeat 50% 50%;
  }

  &-terminal {
    -webkit-mask: url('../../assets/icons/terminal.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/terminal.svg') no-repeat 50% 50%;
  }

  &-command-line {
    -webkit-mask: url('../../assets/icons/command-line.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/command-line.svg') no-repeat 50% 50%;
  }

  &-tick {
    -webkit-mask: url('../../assets/icons/tick.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/tick.svg') no-repeat 50% 50%;
  }

  &-phone {
    -webkit-mask: url('../../assets/icons/phone.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/phone.svg') no-repeat 50% 50%;
  }

  &-display {
    -webkit-mask: url('../../assets/icons/display.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/display.svg') no-repeat 50% 50%;
  }

  &-close-fill {
    -webkit-mask: url('../../assets/icons/close-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/close-fill.svg') no-repeat 50% 50%;
  }

  &-mail {
    -webkit-mask: url('../../assets/icons/mail.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/mail.svg') no-repeat 50% 50%;
  }

  &-people {
    -webkit-mask: url('../../assets/icons/people.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/people.svg') no-repeat 50% 50%;
  }

  &-envelope-check {
    -webkit-mask: url('../../assets/icons/envelope-check.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/envelope-check.svg') no-repeat 50% 50%;
  }

  &-spin {
    -webkit-mask: url('../../assets/icons/spin.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/spin.svg') no-repeat 50% 50%;
  }

  &-ai-generate {
    -webkit-mask: url('../../assets/icons/ai-generate.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/ai-generate.svg') no-repeat 50% 50%;
  }

  &-exclamation-triangle-outline {
    -webkit-mask: url('../../assets/icons/exclamation-triangle-outline.svg')
      no-repeat 50% 50%;
    mask: url('../../assets/icons/exclamation-triangle-outline.svg') no-repeat
      50% 50%;
  }

  &-envelope-paper {
    -webkit-mask: url('../../assets/icons/envelope-paper.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/envelope-paper.svg') no-repeat 50% 50%;
  }

  &-envelope {
    -webkit-mask: url('../../assets/icons/envelope.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/envelope.svg') no-repeat 50% 50%;
  }

  &-hash {
    -webkit-mask: url('../../assets/icons/hash.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/hash.svg') no-repeat 50% 50%;
  }

  &-telegram_logo {
    -webkit-mask: url('../../assets/icons/telegram_logo.png') no-repeat 50% 50%;
    mask: url('../../assets/icons/telegram_logo.png') no-repeat 50% 50%;
  }

  &-send-check {
    -webkit-mask: url('../../assets/icons/send-check.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/send-check.svg') no-repeat 50% 50%;
  }

  &-at {
    -webkit-mask: url('../../assets/icons/at.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/at.svg') no-repeat 50% 50%;
  }

  &-number {
    -webkit-mask: url('../../assets/icons/124.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/at.svg') no-repeat 50% 50%;
  }

  &-number {
    -webkit-mask: url('../../assets/icons/124.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/124.svg') no-repeat 50% 50%;
  }

  &-calendar-week {
    -webkit-mask: url('../../assets/icons/calendar-week.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/calendar-week.svg') no-repeat 50% 50%;
  }

  &-calendar2-event {
    -webkit-mask: url('../../assets/icons/calendar2-event.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/calendar2-event.svg') no-repeat 50% 50%;
  }

  &-list-ul {
    -webkit-mask: url('../../assets/icons/list-ul.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/list-ul.svg') no-repeat 50% 50%;
  }

  &-telephone {
    -webkit-mask: url('../../assets/icons/telephone.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/telephone.svg') no-repeat 50% 50%;
  }
  &-attachment {
    -webkit-mask: url('../../assets/icons/attachment.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/attachment.svg') no-repeat 50% 50%;
  }

  &-box-arrow-up-right {
    -webkit-mask: url('../../assets/icons/box-arrow-up-right.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/box-arrow-up-right.svg') no-repeat 50% 50%;
  }

  &-box-arrow-up-right-mini {
    -webkit-mask: url('../../assets/icons/box-arrow-up-right-mini.svg')
      no-repeat 50% 50%;
    mask: url('../../assets/icons/box-arrow-up-right-mini.svg') no-repeat 50%
      50%;
  }

  &-eye {
    -webkit-mask: url('../../assets/icons/eye.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/eye.svg') no-repeat 50% 50%;
  }

  &-eye-slash {
    -webkit-mask: url('../../assets/icons/eye-slash.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/eye-slash.svg') no-repeat 50% 50%;
  }

  &-danger-triangle {
    -webkit-mask: url('../../assets/icons/danger-triangle.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/danger-triangle.svg') no-repeat 50% 50%;
  }

  &-check-circle {
    -webkit-mask: url('../../assets/icons/check-circle.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/check-circle.svg') no-repeat 50% 50%;
  }

  &-close {
    -webkit-mask: url('../../assets/icons/close.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/close.svg') no-repeat 50% 50%;
  }

  &-bell {
    -webkit-mask: url('../../assets/icons/bell.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/bell.svg') no-repeat 50% 50%;
  }

  &-cog {
    -webkit-mask: url('../../assets/icons/cog.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/cog.svg') no-repeat 50% 50%;
  }

  &-cog2 {
    -webkit-mask: url('../../assets/icons/cog2.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/cog2.svg') no-repeat 50% 50%;
  }

  &-wrench-adjustable-circle {
    -webkit-mask: url('../../assets/icons/wrench-adjustable-circle.svg')
      no-repeat 50% 50%;
    mask: url('../../assets/icons/wrench-adjustable-circle.svg') no-repeat 50%
      50%;
  }

  &-chat-text {
    -webkit-mask: url('../../assets/icons/chat-text.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/chat-text.svg') no-repeat 50% 50%;
  }

  &-text,
  &-constructor-telegram {
    -webkit-mask: url('../../assets/icons/text.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/text.svg') no-repeat 50% 50%;
  }

  &-hand-right {
    -webkit-mask: url('../../assets/icons/hand-right.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/hand-right.svg') no-repeat 50% 50%;
  }

  &-fonts {
    -webkit-mask: url('../../assets/icons/fonts.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/fonts.svg') no-repeat 50% 50%;
  }

  &-clock,
  &-constructor-smart_delay {
    -webkit-mask: url('../../assets/icons/stopwatch.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/stopwatch.svg') no-repeat 50% 50%;
  }

  &-clock_fill {
    -webkit-mask: url('../../assets/icons/stopwatch_fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/stopwatch_fill.svg') no-repeat 50% 50%;
  }

  &-git-branch,
  &-constructor-condition {
    -webkit-mask: url('../../assets/icons/git-branch.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/git-branch.svg') no-repeat 50% 50%;
  }

  &-comments {
    -webkit-mask: url('../../assets/icons/comments.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/comments.svg') no-repeat 50% 50%;
  }

  &-help-circle {
    -webkit-mask: url('../../assets/icons/help-circle.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/help-circle.svg') no-repeat 50% 50%;
  }

  &-home {
    -webkit-mask: url('../../assets/icons/home.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/home.svg') no-repeat 50% 50%;
  }

  &-home-2,
  &-constructor-start {
    -webkit-mask: url('../../assets/icons/home-2.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/home-2.svg') no-repeat 50% 50%;
  }

  &-log-out {
    -webkit-mask: url('../../assets/icons/log-out.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/log-out.svg') no-repeat 50% 50%;
  }

  &-send {
    -webkit-mask: url('../../assets/icons/send.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/send.svg') no-repeat 50% 50%;
  }

  &-trending-up {
    -webkit-mask: url('../../assets/icons/trending-up.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/trending-up.svg') no-repeat 50% 50%;
  }

  &-user-profile {
    -webkit-mask: url('../../assets/icons/user-profile.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/user-profile.svg') no-repeat 50% 50%;
  }

  &-users-three {
    -webkit-mask: url('../../assets/icons/users-three.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/users-three.svg') no-repeat 50% 50%;
  }

  &-chart-pie {
    -webkit-mask: url('../../assets/icons/chart-pie.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/chart-pie.svg') no-repeat 50% 50%;
  }

  &-chat-bot {
    -webkit-mask: url('../../assets/icons/ALL.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/ALL.svg') no-repeat 50% 50%;
  }

  &-hamburger-sidebar-onn {
    -webkit-mask: url('../../assets/icons/hamburger-sidebar-onn.svg') no-repeat
      50% 50%;
    mask: url('../../assets/icons/hamburger-sidebar-onn.svg') no-repeat 50% 50%;
  }

  &-hamburger-sidebar-off {
    -webkit-mask: url('../../assets/icons/hamburger-sidebar-off.svg') no-repeat
      50% 50%;
    mask: url('../../assets/icons/hamburger-sidebar-off.svg') no-repeat 50% 50%;
  }

  &-left {
    -webkit-mask: url('../../assets/icons/left.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/left.svg') no-repeat 50% 50%;
  }

  &-right {
    -webkit-mask: url('../../assets/icons/right.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/right.svg') no-repeat 50% 50%;
  }

  &-braces {
    -webkit-mask: url('../../assets/icons/braces.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/braces.svg') no-repeat 50% 50%;
  }

  &-gift {
    -webkit-mask: url('../../assets/icons/gift.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/gift.svg') no-repeat 50% 50%;
  }

  &-unlock {
    -webkit-mask: url('../../assets/icons/unlock.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/unlock.svg') no-repeat 50% 50%;
  }

  &-add {
    -webkit-mask: url('../../assets/icons/add.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/add.svg') no-repeat 50% 50%;
  }

  &-menu {
    -webkit-mask: url('../../assets/icons/menu-hamburger.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/menu-hamburger.svg') no-repeat 50% 50%;
  }

  &-menu-kebab {
    -webkit-mask: url('../../assets/icons/menu-kebab.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/menu-kebab.svg') no-repeat 50% 50%;
  }

  &-copy {
    -webkit-mask: url('../../assets/icons/copy.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/copy.svg') no-repeat 50% 50%;
  }

  &-exclamation-circle {
    -webkit-mask: url('../../assets/icons/exclamation-circle.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/exclamation-circle.svg') no-repeat 50% 50%;
  }

  &-exclamation-circle-outline {
    -webkit-mask: url('../../assets/icons/exclamation-circle-outline.svg')
      no-repeat 50% 50%;
    mask: url('../../assets/icons/exclamation-circle-outline.svg') no-repeat 50%
      50%;
  }

  &-tick-circle {
    -webkit-mask: url('../../assets/icons/tick-circle.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/tick-circle.svg') no-repeat 50% 50%;
  }

  &-pause {
    -webkit-mask: url('../../assets/icons/pause.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pause.svg') no-repeat 50% 50%;
  }

  &-pause-circle {
    -webkit-mask: url('../../assets/icons/pause-circle.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pause-circle.svg') no-repeat 50% 50%;
  }

  &-arrow-left {
    -webkit-mask: url('../../assets/icons/arrow-left.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/arrow-left.svg') no-repeat 50% 50%;
  }

  &-arrow-path {
    -webkit-mask: url('../../assets/icons/arrow-path.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/arrow-path.svg') no-repeat 50% 50%;
  }

  &-arrow-up {
    -webkit-mask: url('../../assets/icons/up.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/up.svg') no-repeat 50% 50%;
  }

  &-dotes {
    -webkit-mask: url('../../assets/icons/dotes.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/dotes.svg') no-repeat 50% 50%;
  }

  &-focus {
    -webkit-mask: url('../../assets/icons/focus.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/focus.svg') no-repeat 50% 50%;
  }

  &-prev {
    -webkit-mask: url('../../assets/icons/prev.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/prev.svg') no-repeat 50% 50%;
  }

  &-next {
    -webkit-mask: url('../../assets/icons/next.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/next.svg') no-repeat 50% 50%;
  }

  &-zoom-in {
    -webkit-mask: url('../../assets/icons/zoom-in.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/zoom-in.svg') no-repeat 50% 50%;
  }

  &-zoom-out {
    -webkit-mask: url('../../assets/icons/zoom-out.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/zoom-out.svg') no-repeat 50% 50%;
  }

  &-down {
    -webkit-mask: url('../../assets/icons/down.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/down.svg') no-repeat 50% 50%;
  }

  &-tg {
    -webkit-mask: url('../../assets/icons/TELEGRAM.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/TELEGRAM.svg') no-repeat 50% 50%;
    width: 24px;
    height: 24px;
  }

  &-vk {
    -webkit-mask: url('../../assets/icons/vk-logo.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/vk-logo.svg') no-repeat 50% 50%;
  }

  &-vk-regular {
    -webkit-mask: url('../../assets/icons/vk-regular.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/vk-regular.svg') no-repeat 50% 50%;
  }

  &-chat-bot {
    -webkit-mask: url('../../assets/icons/ALL.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/ALL.svg') no-repeat 50% 50%;
    background: $main-color-800;
  }

  &-pen {
    -webkit-mask: url('../../assets/icons/pen.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pen.svg') no-repeat 50% 50%;
  }

  &-rocket {
    -webkit-mask: url('../../assets/icons/rocket.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/rocket.svg') no-repeat 50% 50%;
  }

  &-reader {
    -webkit-mask: url('../../assets/icons/reader.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/reader.svg') no-repeat 50% 50%;
  }

  &-file-text {
    -webkit-mask: url('../../assets/icons/file-text.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/file-text.svg') no-repeat 50% 50%;
  }

  &-edit {
    -webkit-mask: url('../../assets/icons/edit.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/edit.svg') no-repeat 50% 50%;
  }

  &-play {
    -webkit-mask: url('../../assets/icons/play.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/play.svg') no-repeat 50% 50%;
  }

  &-share {
    -webkit-mask: url('../../assets/icons/share.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/share.svg') no-repeat 50% 50%;
  }

  &-copy-16 {
    @extend .icon-copy;
    background: $main-color-800;
  }

  &-trash {
    -webkit-mask: url('../../assets/icons/trash.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/trash.svg') no-repeat 50% 50%;
  }

  &-pause {
    -webkit-mask: url('../../assets/icons/pause.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pause.svg') no-repeat 50% 50%;
  }

  &-delete {
    -webkit-mask: url('../../assets/icons/sad.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/sad.svg') no-repeat 50% 50%;
  }

  &-file-red {
    -webkit-mask: url('../../assets/icons/file-red.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/file-red.svg') no-repeat 50% 50%;
  }

  &-run {
    -webkit-mask: url('../../assets/icons/happy.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/happy.svg') no-repeat 50% 50%;
  }

  &-stop,
  &-delete_channel {
    @extend .icon-delete;
  }

  &-arrow-left-full {
    -webkit-mask: url('../../assets/icons/arrow-left-full.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/arrow-left-full.svg') no-repeat 50% 50%;
  }

  &-arrow-rotate {
    -webkit-mask: url('../../assets/icons/arrow-rotate.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/arrow-rotate.svg') no-repeat 50% 50%;
  }

  &-fi-rr-mode-landscape {
    -webkit-mask: url('../../assets/icons/fi-rr-mode-landscape.svg') no-repeat
      50% 50%;
    mask: url('../../assets/icons/fi-rr-mode-landscape.svg') no-repeat 50% 50%;
  }

  &-document {
    -webkit-mask: url('../../assets/icons/document.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/document.svg') no-repeat 50% 50%;
  }

  &-document-fill {
    -webkit-mask: url('../../assets/icons/document-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/document-fill.svg') no-repeat 50% 50%;
  }

  &-exclamation-triangle {
    -webkit-mask: url('../../assets/icons/exclamation-triangle.svg') no-repeat
      50% 50%;
    mask: url('../../assets/icons/exclamation-triangle.svg') no-repeat 50% 50%;
  }

  &-exclamation-triangle-outline {
    -webkit-mask: url('../../assets/icons/exclamation-triangle-outline.svg')
      no-repeat 50% 50%;
    mask: url('../../assets/icons/exclamation-triangle-outline.svg') no-repeat
      50% 50%;
  }

  &-microphone {
    -webkit-mask: url('../../assets/icons/microphone.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/microphone.svg') no-repeat 50% 50%;
  }

  &-video {
    -webkit-mask: url('../../assets/icons/video.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/video.svg') no-repeat 50% 50%;
  }

  &-music {
    -webkit-mask: url('../../assets/icons/music.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/music.svg') no-repeat 50% 50%;
  }

  &-gallery {
    -webkit-mask: url('../../assets/icons/gallery.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/gallery.svg') no-repeat 50% 50%;
  }

  &-drag {
    -webkit-mask: url('../../assets/icons/drag.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/drag.svg') no-repeat 50% 50%;
  }

  &-tag {
    -webkit-mask: url('../../assets/icons/tag.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/tag.svg') no-repeat 50% 50%;
  }

  &-tag-not {
    -webkit-mask: url('../../assets/icons/tag-not.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/tag-not.svg') no-repeat 50% 50%;
  }

  &-link_line {
    -webkit-mask: url('../../assets/icons/link_line.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/link_line.svg') no-repeat 50% 50%;
  }

  &-code_line {
    -webkit-mask: url('../../assets/icons/code_line.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/code_line.svg') no-repeat 50% 50%;
  }

  &-strikethrough_line {
    -webkit-mask: url('../../assets/icons/strikethrough_line.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/strikethrough_line.svg') no-repeat 50% 50%;
  }

  &-text-generation {
    -webkit-mask: url('../../assets/icons/text_generation.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/text_generation.svg') no-repeat 50% 50%;
  }

  &-underline_line {
    -webkit-mask: url('../../assets/icons/underline_line.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/underline_line.svg') no-repeat 50% 50%;
  }

  &-italic_line {
    -webkit-mask: url('../../assets/icons/italic_line.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/italic_line.svg') no-repeat 50% 50%;
  }

  &-bold_line {
    -webkit-mask: url('../../assets/icons/bold_line.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/bold_line.svg') no-repeat 50% 50%;
  }

  &-paperclip {
    -webkit-mask: url('../../assets/icons/paperclip.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/paperclip.svg') no-repeat 50% 50%;
  }

  &-globe {
    -webkit-mask: url('../../assets/icons/globe.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/globe.svg') no-repeat 50% 50%;
  }

  &-magnet {
    -webkit-mask: url('../../assets/icons/magnet.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/magnet.svg') no-repeat 50% 50%;
  }

  &-text-icon {
    -webkit-mask: url('../../assets/icons/text-icon.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/text-icon.svg') no-repeat 50% 50%;
  }

  &-text-icon_two {
    -webkit-mask: url('../../assets/icons/text-icon_2.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/text-icon_2.svg') no-repeat 50% 50%;
  }

  &-tool {
    -webkit-mask: url('../../assets/icons/tool.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/tool.svg') no-repeat 50% 50%;
  }

  &-time {
    -webkit-mask: url('../../assets/icons/time.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/time.svg') no-repeat 50% 50%;
  }

  &-paper-airplane {
    -webkit-mask: url('../../assets/icons/paper-airplane.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/paper-airplane.svg') no-repeat 50% 50%;
  }

  &-watch {
    -webkit-mask: url('../../assets/icons/watch.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/watch.svg') no-repeat 50% 50%;
  }

  &-calendar {
    -webkit-mask: url('../../assets/icons/сalender.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/сalender.svg') no-repeat 50% 50%;
  }

  &-calendar-days {
    -webkit-mask: url('../../assets/icons/calendar-days.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/calendar-days.svg') no-repeat 50% 50%;
  }

  &-search {
    -webkit-mask: url('../../assets/icons/search.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/search.svg') no-repeat 50% 50%;
  }

  &-magnifying-glass {
    -webkit-mask: url('../../assets/icons/magnifying_glass.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/magnifying_glass.svg') no-repeat 50% 50%;
  }

  &-filter {
    -webkit-mask: url('../../assets/icons/filter.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/filter.svg') no-repeat 50% 50%;
  }

  &-funnel {
    -webkit-mask: url('../../assets/icons/funnel.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/funnel.svg') no-repeat 50% 50%;
  }

  &-noun-ai,
  &-constructor-ai_step {
    -webkit-mask: url('../../assets/icons/noun-ai.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/noun-ai.svg') no-repeat 50% 50%;
  }

  &-sort-down {
    -webkit-mask: url('../../assets/icons/sort-down.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/sort-down.svg') no-repeat 50% 50%;
  }

  &-send-plus {
    -webkit-mask: url('../../assets/icons/send-plus.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/send-plus.svg') no-repeat 50% 50%;
  }

  &-send-dash {
    -webkit-mask: url('../../assets/icons/send-dash.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/send-dash.svg') no-repeat 50% 50%;
  }

  &-journal-plus {
    -webkit-mask: url('../../assets/icons/journal-plus.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/journal-plus.svg') no-repeat 50% 50%;
  }

  &-journal-minus {
    -webkit-mask: url('../../assets/icons/journal-minus.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/journal-minus.svg') no-repeat 50% 50%;
  }

  &-calculator {
    -webkit-mask: url('../../assets/icons/calculator.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/calculator.svg') no-repeat 50% 50%;
  }

  &-person-dash {
    -webkit-mask: url('../../assets/icons/person-dash.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/person-dash.svg') no-repeat 50% 50%;
  }

  &-person-plus {
    -webkit-mask: url('../../assets/icons/person-plus.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/person-plus.svg') no-repeat 50% 50%;
  }

  &-hourglass-split {
    -webkit-mask: url('../../assets/icons/hourglass-split.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/hourglass-split.svg') no-repeat 50% 50%;
  }

  &-hourglass-bottom {
    -webkit-mask: url('../../assets/icons/hourglass-bottom.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/hourglass-bottom.svg') no-repeat 50% 50%;
  }

  &-waiting {
    -webkit-mask: url('../../assets/icons/waiting.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/waiting.svg') no-repeat 50% 50%;
  }

  &-journal-richtext {
    -webkit-mask: url('../../assets/icons/journal-richtext.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/journal-richtext.svg') no-repeat 50% 50%;
  }

  &-eye-fill {
    -webkit-mask: url('../../assets/icons/eye-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/eye-fill.svg') no-repeat 50% 50%;
  }

  &-hand-index-thumb-fill {
    -webkit-mask: url('../../assets/icons/hand-index-thumb-fill.svg') no-repeat
      50% 50%;
    mask: url('../../assets/icons/hand-index-thumb-fill.svg') no-repeat 50% 50%;
  }

  &-person-check-fill {
    -webkit-mask: url('../../assets/icons/person-check-fill.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/person-check-fill.svg') no-repeat 50% 50%;
  }

  &-time-fill {
    -webkit-mask: url('../../assets/icons/time-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/time-fill.svg') no-repeat 50% 50%;
  }

  &-hand-index,
  &-constructor-action_group {
    -webkit-mask: url('../../assets/icons/hand-index.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/hand-index.svg') no-repeat 50% 50%;
  }

  &-person-check {
    -webkit-mask: url('../../assets/icons/person-check.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/person-check.svg') no-repeat 50% 50%;
  }

  &-pause-circle2 {
    -webkit-mask: url('../../assets/icons/pause-circle2.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pause-circle2.svg') no-repeat 50% 50%;
  }

  &-download {
    -webkit-mask: url('../../assets/icons/download.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/download.svg') no-repeat 50% 50%;
  }

  &-upload {
    -webkit-mask: url('../../assets/icons/upload.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/upload.svg') no-repeat 50% 50%;
  }

  &-cloud-arrow-down {
    -webkit-mask: url('../../assets/icons/cloud-arrow-down.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/cloud-arrow-down.svg') no-repeat 50% 50%;
  }

  &-check-all {
    -webkit-mask: url('../../assets/icons/check-all.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/check-all.svg') no-repeat 50% 50%;
  }

  &-img {
    -webkit-mask: url('../../assets/icons/img.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/img.svg') no-repeat 50% 50%;
  }

  &-AI-generator {
    -webkit-mask: url('../../assets/icons/noun-ai.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/noun-ai.svg') no-repeat 50% 50%;
  }

  &-music-fill {
    -webkit-mask: url('../../assets/icons/music-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/music-fill.svg') no-repeat 50% 50%;
  }

  &-play-fill {
    -webkit-mask: url('../../assets/icons/play-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/play-fill.svg') no-repeat 50% 50%;
  }

  &-volume-on {
    -webkit-mask: url('../../assets/icons/volume-on.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/volume-on.svg') no-repeat 50% 50%;
  }

  &-volume-off {
    -webkit-mask: url('../../assets/icons/volume-off.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/volume-off.svg') no-repeat 50% 50%;
  }

  &-video-fill {
    -webkit-mask: url('../../assets/icons/video-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/video-fill.svg') no-repeat 50% 50%;
  }

  &-save {
    -webkit-mask: url('../../assets/icons/save.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/save.svg') no-repeat 50% 50%;
  }

  &-keyboard {
    -webkit-mask: url('../../assets/icons/keyboard.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/keyboard.svg') no-repeat 50% 50%;
  }

  &-service {
    -webkit-mask: url('../../assets/icons/service.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/service.svg') no-repeat 50% 50%;
  }

  &-megaphone {
    -webkit-mask: url('../../assets/icons/megaphone.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/megaphone.svg') no-repeat 50% 50%;
  }

  &-storefront {
    -webkit-mask: url('../../assets/icons/storefront.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/storefront.svg') no-repeat 50% 50%;
  }

  &-information-circle {
    -webkit-mask: url('../../assets/icons/information-circle.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/information-circle.svg') no-repeat 50% 50%;
  }

  &-premium {
    -webkit-mask: url('../../assets/icons/premium.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/premium.svg') no-repeat 50% 50%;
  }

  &-tools {
    -webkit-mask: url('../../assets/icons/tools.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/tools.svg') no-repeat 50% 50%;
  }

  &-bar-chart {
    -webkit-mask: url('../../assets/icons/bar-chart.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/bar-chart.svg') no-repeat 50% 50%;
  }

  &-credit-card {
    -webkit-mask: url('../../assets/icons/credit-card.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/credit-card.svg') no-repeat 50% 50%;
  }

  &-cd {
    @extend .icon-credit-card;
    background: $black-700;
  }

  &-ya {
    background: url('../../assets/icons/ya.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-wm {
    background: url('../../assets/icons/wm.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-bt {
    background: url('../../assets/icons/bt.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-pa {
    background: url('../../assets/icons/pa.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-pm {
    background: url('../../assets/icons/pm.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-qw {
    background: url('../../assets/icons/qw.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-logo {
    background: url('../../assets/icons/logo.svg') center transparent no-repeat !important;
    background-size: contain !important;
  }

  &-info-circle {
    -webkit-mask: url('../../assets/icons/info-circle.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/info-circle.svg') no-repeat 50% 50%;
  }

  &-question-circle-fill {
    -webkit-mask: url('../../assets/icons/question-circle-fill.svg') no-repeat
      50% 50%;
    mask: url('../../assets/icons/question-circle-fill.svg') no-repeat 50% 50%;
  }

  &-error {
    -webkit-mask: url('../../assets/icons/error.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/error.svg') no-repeat 50% 50%;
  }

  &-building-storefront {
    -webkit-mask: url('../../assets/icons/building-storefront.svg') no-repeat
      50% 50%;
    mask: url('../../assets/icons/building-storefront.svg') no-repeat 50% 50%;
  }

  &-lock-fill {
    -webkit-mask: url('../../assets/icons/lock-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/lock-fill.svg') no-repeat 50% 50%;
  }

  &-text-left {
    -webkit-mask: url('../../assets/icons/text-left.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/text-left.svg') no-repeat 50% 50%;
  }

  &-text-right {
    @extend .icon-text-left;
    transform: rotate(180deg);
  }

  &-text-center {
    -webkit-mask: url('../../assets/icons/text-center.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/text-center.svg') no-repeat 50% 50%;
  }

  &-bot_published {
    @extend .icon-chat-bot;
    background: $success-500;
  }

  &-card-heading {
    -webkit-mask: url('../../assets/icons/card-heading.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/card-heading.svg') no-repeat 50% 50%;
  }

  &-file-earmark {
    -webkit-mask: url('../../assets/icons/file-earmark-frame.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/file-earmark-frame.svg') no-repeat 50% 50%;
  }

  &-arrow-small-left {
    -webkit-mask: url('../../assets/icons/arrow-small-left.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/arrow-small-left.svg') no-repeat 50% 50%;
  }

  &-wrench-screwdriver {
    -webkit-mask: url('../../assets/icons/wrench-screwdriver.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/wrench-screwdriver.svg') no-repeat 50% 50%;
  }

  &-arrow-repeat {
    -webkit-mask: url('../../assets/icons/arrow-repeat.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/arrow-repeat.svg') no-repeat 50% 50%;
  }

  &-command {
    -webkit-mask: url('../../assets/icons/command.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/command.svg') no-repeat 50% 50%;
  }

  &-mortarboard {
    -webkit-mask: url('../../assets/icons/mortarboard.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/mortarboard.svg') no-repeat 50% 50%;
  }

  &-easel2-fill {
    -webkit-mask: url('../../assets/icons/easel2-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/easel2-fill.svg') no-repeat 50% 50%;
  }

  &-images {
    -webkit-mask: url('../../assets/icons/images.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/images.svg') no-repeat 50% 50%;
  }

  &-wallet {
    -webkit-mask: url('../../assets/icons/wallet2.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/wallet2.svg') no-repeat 50% 50%;
  }

  &-user-group {
    -webkit-mask: url('../../assets/icons/user-group.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/user-group.svg') no-repeat 50% 50%;
  }

  &-clipboard-document-check {
    -webkit-mask: url('../../assets/icons/clipboard-document-check.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/clipboard-document-check.svg') no-repeat 50% 50%;
  }

  &-archive {
    -webkit-mask: url('../../assets/icons/archive.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/archive.svg') no-repeat 50% 50%;
  }

  &-graph-up-arrow {
    -webkit-mask: url('../../assets/icons/graph-up-arrow.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/graph-up-arrow.svg') no-repeat 50% 50%;
  }

  &-credit-card-2 {
    -webkit-mask: url('../../assets/icons/credit-card-2.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/credit-card-2.svg') no-repeat 50% 50%;
  }

  &-easel {
    -webkit-mask: url('../../assets/icons/easel.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/easel.svg') no-repeat 50% 50%;
  }

  &-pin-angle {
    -webkit-mask: url('../../assets/icons/pin-angle.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pin-angle.svg') no-repeat 50% 50%;
  }

  &-dice,
  &-constructor-split {
    -webkit-mask: url('../../assets/icons/dice.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/dice.svg') no-repeat 50% 50%;
  }

  &-percent {
    -webkit-mask: url('../../assets/icons/percent.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/percent.svg') no-repeat 50% 50%;
  }

  &-pin-angle-fill {
    -webkit-mask: url('../../assets/icons/pin-angle-fill.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pin-angle-fill.svg') no-repeat 50% 50%;
  }

  &-link-45 {
    -webkit-mask: url('../../assets/icons/link-45deg.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/link-45deg.svg') no-repeat 50% 50%;
  }

  &-check {
    -webkit-mask: url('../../assets/icons/check.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/check.svg') no-repeat 50% 50%;
  }

  &-pencil {
    -webkit-mask: url('../../assets/icons/pencil.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/pencil.svg') no-repeat 50% 50%;
  }

  &-grid {
    -webkit-mask: url('../../assets/icons/grid.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/grid.svg') no-repeat 50% 50%;
  }

  &-gear {
    -webkit-mask: url('../../assets/icons/gear.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/gear.svg') no-repeat 50% 50%;
  }

  &-building-office {
    -webkit-mask: url('../../assets/icons/building-office.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/building-office.svg') no-repeat 50% 50%;
  }

  &-building-office-2 {
    -webkit-mask: url('../../assets/icons/building-office-2.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/building-office-2.svg') no-repeat 50% 50%;
  }

  &-bar-chart-steps {
    -webkit-mask: url('../../assets/icons/bar-chart-steps.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/bar-chart-steps.svg') no-repeat 50% 50%;
  }

  &-arrow-small-up {
    -webkit-mask: url('../../assets/icons/arrow-small-up.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/arrow-small-up.svg') no-repeat 50% 50%;
  }

  &-power {
    -webkit-mask: url('../../assets/icons/power.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/power.svg') no-repeat 50% 50%;
  }

  &-check-tariff {
    -webkit-mask: url('../../assets/icons/check-tariff.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/check-tariff.svg') no-repeat 50% 50%;
  }

  &-x-mark {
    -webkit-mask: url('../../assets/icons/x-mark.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/x-mark.svg') no-repeat 50% 50%;
  }

  &-receipt {
    -webkit-mask: url('../../assets/icons/receipt.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/receipt.svg') no-repeat 50% 50%;
    width: 16px;
    height: 16px;
    background: $black-500;
  }

  &-created {
    -webkit-mask: url('../../assets/icons/created.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/created.svg') no-repeat 50% 50%;
    width: 16px;
    height: 16px;
    background: $black-500;
  }

  &-succeeded {
    @extend .icon-check-tariff;
    background: $success-500;
    width: 16px;
    height: 16px;
  }

  &-globe2 {
    -webkit-mask: url('../../assets/icons/globe2.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/globe2.svg') no-repeat 50% 50%;
  }

  &-link {
    -webkit-mask: url('../../assets/icons/link.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/link.svg') no-repeat 50% 50%;
  }

  &-box-arrow {
    -webkit-mask: url('../../assets/icons/box-arrow.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/box-arrow.svg') no-repeat 50% 50%;
  }

  &-identification {
    -webkit-mask: url('../../assets/icons/identification.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/identification.svg') no-repeat 50% 50%;
  }

  &-email {
    -webkit-mask: url('../../assets/icons/email.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/email.svg') no-repeat 50% 50%;
  }

  &-window-split {
    -webkit-mask: url('../../assets/icons/window-split.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/window-split.svg') no-repeat 50% 50%;
  }

  &-globe-alt {
    -webkit-mask: url('../../assets/icons/globe-alt.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/globe-alt.svg') no-repeat 50% 50%;
  }

  &-call {
    -webkit-mask: url('../../assets/icons/call.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/call.svg') no-repeat 50% 50%;
  }

  &-2fa {
    -webkit-mask: url('../../assets/icons/2fa.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/2fa.svg') no-repeat 50% 50%;
  }

  &-lock {
    -webkit-mask: url('../../assets/icons/lock.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/lock.svg') no-repeat 50% 50%;
  }

  &-check-outline {
    -webkit-mask: url('../../assets/icons/check-outline.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/check-outline.svg') no-repeat 50% 50%;
  }

  &-document-one {
    -webkit-mask: url('../../assets/icons/document-one.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/document-one.svg') no-repeat 50% 50%;
  }

  &-computer-desktop {
    -webkit-mask: url('../../assets/icons/computer-desktop.svg') no-repeat 50%
      50%;
    mask: url('../../assets/icons/computer-desktop.svg') no-repeat 50% 50%;
  }

  &-plus {
    -webkit-mask: url('../../assets/icons/plus.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/plus.svg') no-repeat 50% 50%;
  }

  &-back {
    -webkit-mask: url('../../assets/icons/back.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/back.svg') no-repeat 50% 50%;
  }

  &-123 {
    -webkit-mask: url('../../assets/icons/123.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/123.svg') no-repeat 50% 50%;
  }

  &-exclamation-triangle-two {
    -webkit-mask: url('../../assets/icons/exclamation-triangle-two.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/exclamation-triangle-two.svg') no-repeat 50% 50%;
  }

  &-check-icon-user {
    -webkit-mask: url('../../assets/icons/check-icon-user.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/check-icon-user.svg') no-repeat 50% 50%;
  }

  &-presentation-chart-line {
    -webkit-mask: url('../../assets/icons/presentation-chart-line.svg') no-repeat 50% 50%;
    mask: url('../../assets/icons/presentation-chart-line.svg') no-repeat 50% 50%;
  }
}
