.mark-autocomplete {
  padding: 15px 16px;
  background: rgba(246, 247, 251, 0.24);
  border: 1px solid $black-500;
  border-radius: 8px;
  outline: none;
  width: 100%;
  @include text(16px, 16px, 400, $black-700);

  @include for-tablet {
    padding: 12px;
    @include text(12px, 12px, 400, $black-700);
  }

  &:hover, &:active {
    border-color: $main-color-800;
  }
}
