.mat-mdc-menu-content {
  :last-child > span {
    color: $error-500 !important;
  }
}

.mat-mdc-menu-item-text {
  position: relative;
  color: $black-700 !important;

  .icon {
    background-color: $black-700 !important;
    position: relative;
    width: 15px !important;
    height: 16px !important;
    margin-right: 9px !important;
  }
  .icon-trash {
    width: 13px !important;
    height: 13px !important;
    background-color: $error-500 !important;
  }
}
