@import 'components/color';
@import 'mixin';
@import 'breakpoints';

* {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.ace_editor {
  font: 12px / normal monospace !important;

  * {
    font: 12px / normal monospace !important;
  }
}

html,
body {
  height: 100%;
  color: $black-900;
}

body {
  margin: 0;
  font-style: normal;
  background: #fff;
}

.w-100 {
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link-second {
  text-decoration: none;
  cursor: pointer;
  color: var(--Blue-800_PR, #1565c0);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px; /* 100% */
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  color: inherit;
  background-color: transparent;
}

.mat-mdc-row,
mat-header-row,
mat-footer-row,
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell,
.mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-surface {
  padding: 24px;
  box-shadow: 0 6px 10px 4px #00000026 !important;
  border-radius: 16px !important;
}

.mat-mdc-menu-panel {
  background: #fff;
}

.mat-mdc-tooltip,
.mat-tooltip {
  background: #65666a;
  opacity: 84% !important;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  position: relative;
  border-radius: 8px;
  // min-width: 280px !important;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  &::after {
    content: '';
    position: absolute;
    //top: 0;
    bottom: -4px;
    left: calc(50% - 8px);
    width: 16px;
    height: 16px;
    background: #65666a;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
  .mdc-tooltip__surface {
    background: rgba(97, 97, 97, 0.9);
  }
  .mdc-tooltip__surface-animation {
    // width: 280px !important;
    max-width: 300px !important;
    font-size: 12px !important;
    background: #65666a !important;
  }
}

.mat-drawer.mat-sidenav.mat-drawer-side {
  box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
}

.mat-mdc-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-outlined-button.mat-primary.mat-button-disabled,
.mat-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mobile:not(.sidenav__mobile) {
  display: none;
}

.channel-type-item {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  &__text {
  }

  &.p-0 {
    padding: 0;
  }
}

*::-webkit-scrollbar {
  background-color: $white-100;
  width: 16px;
}

*::-webkit-scrollbar-track {
  background-color: $white-100;
}

*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

*::-webkit-scrollbar-button {
  display: none;
}

.s-content {
  gap: 8px;

  &-item {
    column-gap: 8px;

    span.active {
      text-decoration-line: line-through;
      color: #c2c2c6;
    }

    .icon {
      background-color: $triadic3-800;
      width: 16px;
      height: 16px;
    }

    &:not(.icon) {
      @include text(12px, 16px, 400, $black-900);
    }

    .disabled {
      text-decoration: line-through;
      color: #c2c2c6;
    }
  }

  &-line {
    width: 100%;
    height: 1px;
    background: $triadic3-50;
  }
}

@media screen and (max-width: 576px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .mat-mdc-dialog-surface {
    padding: 16px;
  }

  .cdk-overlay-pane {
    max-width: calc(100vw - 32px) !important;
  }
}

.drag-btn {
  padding: 10px !important;
  cursor: move;

  .icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-24 {
  margin-bottom: 24px;
}

.cursor-pointer {
  cursor: pointer;
}

.tag-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 12px;

  &__img {
    width: 300px;
  }

  &__title {
    @include text(16px, 24px, 400);
    text-align: center;

    @media screen and (min-width: 1280px) {
      @include text(24px, 24px, 400);
    }
  }

  &__btn {
    border: 1px solid #a3a4a8;
    @include text(14px, 16px, 400, $black-700);
    text-align: center;
    padding: 12px 28px;
    background: $white-100;
    border-radius: 8px;
    cursor: pointer;
    width: 230px;

    @media screen and (min-width: 768px) {
      @include text(16px, 24px, 400, $black-700);
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.variable-item-highlighter {
  color: #7c3aed;
  box-shadow: 0px 1px 2px 0px #00000026;
  padding: 2px 8px;
  border: 0.2px solid #65666a85;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;

  &::before {
    content: url('../assets/icons/file-text-fill.svg');
    position: relative;
    top: 2px;
    left: 0;
    display: inline-block;
    width: 12px;
    height: 12px;
    color: #7c3aed;
    margin-right: 5px;
  }
}

.disconnect-open_ai {
  .delete-icon {
    left: -30px !important;
  }

  .delete-confirm__description {
    @include text(
      16px !important,
      24px !important,
      400 !important,
      $black-700 !important
    );

    @include for-tablet {
      @include text(
        14px !important,
        16px !important,
        400 !important,
        $black-700 !important
      );
    }
  }

  .btn-outline-danger {
    font-weight: 400;
    color: $error-700;
  }
}

.cdk-global-overlay-wrapper {
  overflow-x: auto;
}
