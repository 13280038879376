.mat-mdc-slide-toggle {
  $parent: &;

  #{$parent} {
    &-bar {
      display: flex;
      width: 48px;
      height: 24px;
      align-items: center;
      border-radius: 24px;
      background: var(--black-300, #E4E5E9);
    }

    &-thumb {
      width: 16px;
      height: 16px;
      background-color: #65666A;


      &-container {
        transform: translate3d(2px, 0, 0);
        top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  &.mat-checked {
    .mat-mdc-slide-toggle {
      &-bar {
        background-color: #BBDEFB !important;
      }

      &-thumb {
        background-color: #1565C0 !important;

        &-container {
          transform: translate3d(26px, 0, 0) !important;
        }
      }
    }
  }


  &.small-size {
    #{$parent} {
      &-bar {
        width: 32px;
        height: 16px;
        border-radius: 24px;
      }

      &-thumb {
        width: 12px;
        height: 12px;


        &-container {
          width: 16px;
          height: 16px;
          transform: translate3d(-1px, 0, 0);
          top: 0;
        }
      }
      &-ripple {
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        height: 30px;
        width: 30px;
      }

    }

    &.mat-checked {
      .mat-mdc-slide-toggle {

        &-thumb {

          &-container {
            transform: translate3d(14px, 0, 0) !important;
          }
        }


      }
    }
  }
}
