/*COLORS*/
$main-color-50: #E3F2FD;
$main-color-100: #BBDEFB;
$main-color-300: #64B5F6;
$main-color-400: #42A5F5;
$main-color-500: #2196F3;
$main-color-600: #1E88E5;
$main-color-700: #1976D2;
$main-color-800: #1565C0;

$yellow-10: #FCFCE8;
$yellow-50: #fdfbe6;
$yellow-100: #fbf3c1;
$yellow-500: #f1d837;
$yellow-600: #eec632;
$yellow-700: #ebb02c;
$yellow-800: #FFC700;

$black-50: #fbfaff;
$black-100: #f6f7fb;
$black-200: #f1f1f5;
$black-300: #e4e5e9;
$black-400: #C2C2C6;
$black-500: #a3a4a8;
$black-600: #797A7E;
$black-700: #65666a;
$black-800: #46474A;
$black-900: #252528;

$blue-50: #E3F2FD;
$blue-100: #bbdefb;
$blue-700: #1976D2;
$blue-800: #1565C0;

$white-30: rgba(255, 255, 255, 0.3);
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);
$white-90: rgba(255, 255, 255, 0.9);
$white-100: #ffffff;

$error-50: #feebee;
$error-100: #fecdd1;
$error-300: #E17271;
$error-400: #EB534E;
$error-500: #ef4434;
$error-600: #E03A33;
$error-700: #CE302D;
$error-900: #B21E1B;
$error-800: #C12926;

$attention-50: #fff3e0;
$attention-100: #ffdfb1;
$attention-200: #ffca7f;
$attention-400: #ffa425;
$attention-500: #ff9400;
$attention-600: #fb8901;
$attention-700: #F57902;

$success-50: #E8F5E9;
$success-90:#E8F5E9;
$success-100: #C8E6C9;
$success-500: #4CAF50;
$success-600: #2E7D31;
$success-700: #388E3B;
$success-900: #1B5E1F;

$triadic1-50: #F3E5F9;
$triadic1-100: #D0C1F2;
$triadic1-500: #9717CD;
$triadic1-800: #5B13B9;

$triadic2-50: #DEF5F5;
$triadic2-100: #AEE7E6;
$triadic2-500: #00AFB9;
$triadic2-800: #007779;

$triadic3-50: #FBE4EE;
$triadic3-100: #F6BBD4;
$triadic3-500: #E8176F;
$triadic3-800: #AB1360;

$triadic4-50: #EEF2FF;
$triadic4-800: #6366F1;

$purple-900: #2310AE;
