@import 'color';

@mixin invalid-fields {
  /*INPUT*/
  .ui-input {
    &__container {
      border-color: $attention-500;
      background-color: #fff3e080;

      .icon {
        background: $attention-500;
      }
    }
  }
}

.ng-submitted {
  .ng-invalid {
    @include invalid-fields;
  }
}

app-input.ng-dirty.ng-invalid,
.ng-dirty.ng-invalid app-select,
app-select.ng-dirty.ng-invalid {
  @include invalid-fields;

  &.ng-error {
    .ui-input,
    .ui-select {
      &__container {
        border-color: $error-500 !important;
        background-color: #feebee80;

        .icon {
          background: $error-500;
        }
      }
    }
  }
}

.form-control {
  width: 100%;
  display: block;

  &__error {
    margin-top: 4px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $error-500;

    .icon {
      width: 16px;
      height: 16px;
      background: $error-500;
      margin-right: 8px;
    }

    &.warning {
      color: $attention-500;
      .icon {
        background: $attention-500;
      }
    }
  }

  &--invalid {
    .input-holder {
      border-color: $attention-500 !important;
    }

    .hint {
      &-text {
        color: $attention-500 !important;
      }

      &-icon {
        background-color: $attention-500;
      }
    }
  }

  &__hint {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $black-700;
    margin-top: 4px;

    .icon {
      margin-right: 4px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      background: $black-700;
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

/* CHECKBOX */
.checkbox {
  .mdc-checkbox__background {
    width: 24px !important;
    height: 24px !important;
  }

  &.mdc-checkbox--checked:not(.mdc-checkbox--disabled).mdc-checkbox--primary
    .mdc-checkbox__ripple,
  &.mdc-checkbox:active:not(.mdc-checkbox--disabled).mdc-checkbox--primary
    .mdc-checkbox__ripple
    .mat-ripple-element {
    background: $main-color-50;
  }

  &.mdc-checkbox--indeterminate.mdc-checkbox--primary .mdc-checkbox__background,
  &.mdc-checkbox--checked.mdc-checkbox--primary .mdc-checkbox__background {
    background: $main-color-800;
  }

  .mdc-checkbox__frame {
    border-color: $main-color-800;
    border-width: 1px;
  }

  .mdc-checkbox__label {
    color: $black-700;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &-sm .mdc-checkbox__background {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 576px) {
  .form-control {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &__error {
      font-size: 10px;
      line-height: 10px;

      .icon {
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }
    }

    &__hint {
      font-size: 10px;
      line-height: 10px;

      .icon {
        width: 10px;
        min-width: 10px;
        height: 10px;
      }
    }
  }
}
