@import 'color';

.toggle-button {
  &.mat-button-toggle-group-appearance-standard {
    border-radius: 8px;
  }
  .mat-button-toggle-checked {
    background-color: $main-color-800;

    &.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      color: #fff;
    }
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 12px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black-700;
  }
}
