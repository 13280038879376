@import "../breakpoints";

.alert {
  padding: 16px;
  display: flex;
  column-gap: 8px;

  @include for-tablet {
    padding: 8px;
  }

  .icon {
    min-width: 24px;
  }

  &-title {
    @include text(14px, 20px, 700);

    @include for-tablet {
      @include text(10px, 12px, 700)
    }
  }

  &-text {
    @include text(12px, 16px, 400);
    @include for-tablet {
      @include text(10px, 12px, 400);
    }
  }

  &-warning {
    background: $attention-50;
    border-radius: 8px;

    .icon {
      background-color: $attention-500;
      min-width: 24px;
    }
  }

  &-error {
    background: $error-50;
    border-radius: 6px;

    .icon {
      background-color: $error-500;
      min-width: 24px;
    }

    .alert-title, .alert-text {
      color: $error-500;
    }
  }

  &-info {
    border-radius: 6px;
    background: #EFF6FF;

    .alert-title, .alert-text {
      color: #1D4ED8;
    }

    .icon {
      background-color: #60A5FA;
    }

  }
}
