@import 'color';

table.mat-mdc-table {
  background-color: transparent;
  border-spacing: 0 2px !important;
}

.mat-mdc-table thead {
  height: 68px;

  tr.mat-mdc-header-row {
    background: $white-100;
    box-shadow: none;
    border-radius: 8px;

    th:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}

.mat-mdc-table {
  th.mat-mdc-header-cell,
  td {
    @include text(14px, 16px, 400, $black-700);
  }

  tr.mat-mdc-row {
    height: 68px;
    background-color: $white-100;

    td:first-child,
    td:nth-child(2),
    th:first-child,
    th:nth-child(2) {
      min-width: 64px;
    }

    td:last-child,
    th:last-child {
      min-width: 50px;
    }

    td {
      border: none;
    }

    td:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    td:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &.new-table {
    thead {
      height: 48px;
    }

    tr {
      &.mat-mdc-header-row {
        background: $black-50;
        border-radius: 8px;
      }
    }

    th.mat-mdc-header-cell {
      border: none;
    }

    tr.mat-mdc-row {
      min-height: 80px;
      height: 80px;

      td {
        padding: 40px 16px 16px 16px;
        border-bottom: 1px solid #e4e5e9;
        border-radius: 0;
      }
    }
  }
}

::ng-deep .mat-sort-header-arrow {
  .mat-sort-header-stem {
    display: none;
  }

  .mat-sort-header-indicator {
    opacity: 1;
    color: black;
    font-weight: bold;

    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }
  }
}

::ng-deep [aria-sort="ascending"] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        content: '';
        position: absolute;
        top: -3px;
        width: 16px;
        height: 16px;
        background-image: url('~src/assets/icons/sort-down.svg');
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
}

::ng-deep [aria-sort="descending"] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        content: '';
        position: absolute;
        top: -12px;
        width: 16px;
        height: 16px;
        background-image: url('~src/assets/icons/sort-down.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .mat-mdc-table {
    th.mat-mdc-header-cell,
    td {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    thead {
      height: 48px;
    }

    tr.mat-mdc-header-row {
      height: unset;
    }

    tr.mat-mdc-row {
      height: 48px;
    }
  }
}
