@import "color";
@import "../../styles/breakpoints";
@import "../../styles/mixin";

.card {
  background-color: $white-100;
  padding: 16px;
  border-radius: 8px;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-item {
    background-color: $white-100;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @include for-tablet {
      margin-bottom: 8px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;

    &_number {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: $main-color-800;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $white-100;
      margin-right: 16px;
    }

    &_part-1 {
      @include text(20px, 20px, 700, $main-color-800);
      margin-right: 5px;
    }

    @include for-tablet {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__subtitle {
    max-width: 740px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $black-700;

    @include for-tablet {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__content {
    margin-top: 16px;
    padding: 16px 0 24px 24px;
    background: $black-50;
    border-radius: 16px;

    @include for-tablet {
      margin: 0;
      background: $white-100;
      padding: 0;
    }

    &-title {
      @include text(20px, 20px, 600, $black-700);
      margin-bottom: 10px;

      @include for-tablet {
        display: none;
      }
    }
  }

  @include for-tablet {
    padding: 16px 16px 0 16px;
  }
}
