@import "color";
@import "../mixin";
@import "../breakpoints";

.mark-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  @include for-tablet {
    margin-bottom: 8px;
  }
}

.mark-item {
  background: $main-color-50;
  border: 1px solid $main-color-800;
  border-radius: 4px;
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  @include text(16px, 16px, 300);

  @include for-tablet {
    @include text(12px, 12px, 300);
    padding: 6px 8px;
  }

  .icon {
    width: 16px;
    height: 16px;
    background-color: $black-700;
    cursor: pointer;
  }
}
