/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@ctrl/ngx-emoji-mart/picker';
@import '../node_modules/animate.css/animate.min.css';
@import 'styles/index';

.mdc-snackbar {
  &__surface {
    background-color: #ffffff !important;
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch__track {
    width: 32px !important;
    height: 16px !important;
    background: #e4e5e9 !important;
    border-radius: 24px !important;

    &::after {
      background: $success-700 !important;
    }

    &::before {
      background: $black-500 !important;
    }
  }

  .mdc-switch__handle {
    position: absolute !important;
    width: 12px !important;
    height: 12px !important;
    left: 0 !important;
    top: 3px !important;
    background-color: #65666a !important;
    border-radius: 12px !important;

    &::after {
      background: $white-100 !important;
    }

    .mdc-switch_shadow {
      display: none;
    }

    .mdc-switch__icon {
      display: none !important;
    }

    .mdc-switch__ripple {
      width: 12px !important;
      height: 12px !important;
    }
  }

  .mdc-switch__handle-track {
    top: 5px !important;
    left: 3px !important;
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__track {
      background-color: #ffffff !important;
    }

    .mdc-switch__handle {
      background-color: #ffffff !important;
    }

    .mdc-switch__handle-track {
      transform: translate3d(14px, 0, 0) !important;
    }
  }
}

strong {
  color: $black-900;
}

.mat-mdc-icon-button {
  width: 36px !important;
  height: 36px !important;
}

div.mat-mdc-autocomplete-panel {
  box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  background: $white-100;
  max-height: 220px;

  .option__item {
    padding: 10px 16px !important;
    height: auto !important;

    .mat-option-text {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    p {
      @include text(12px, 12px, 400);
    }

    &:last-child {
      border: none;
    }
  }
}

.custom-tooltip {
  position: absolute;
  background-color: $black-700;
  padding: 8px;
  border-radius: 8px;
  z-index: 1000;
  @include text(12px, 16px, 400, $white-100);

  &:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: calc(50% - 8px);
    height: 8px;
    width: 8px;
    background: $black-700;
    transform: rotate(45deg);
  }
}

.custom-snackbar {
  background: #ffffff;
  padding: 16px;
  box-shadow: 0 10px 15px -3px #0000001a;
  border-radius: 8px;
  color: $black-900;
}

.price-hint {
  margin-top: 4px;
  @include text(12px, 16px, 400, $black-700);
}

.mat-autocomplete-panel {
  background: $white-100 !important;
  box-shadow: 0 6px 10px 4px #00000026 !important;
  border-radius: 8px !important;
}

ol,
ul {
  padding-left: 30px;
}

code {
  white-space: wrap;
  word-break: break-word;
}

.mat-mdc-autocomplete-panel {
  box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  background: $white-100 !important;
  max-height: 220px !important;

  .mdc-list-item {
    padding: 10px 16px !important;
    height: auto !important;

    .mdc-list-item__primary-text {
      display: flex !important;
      align-items: center !important;
      gap: 8px !important;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    p {
      @include text(12px, 12px, 400);
    }

    &:last-child {
      border: none;
    }
  }
}

.mat-mdc-snack-bar-container {
  &.mat-mdc-snack-bar-surface {
    background-color: #ffffff !important;
  }

  .mdc-snackbar__surface {
    background-color: #ffffff !important;
  }

  .mdc-snackbar__label {
    color: inherit !important;
  }
}

.mat-mdc-menu-content {
  padding: 0 !important;
}
